<template>
  <div class="contactus">
    <div class="contactus-title flex flex-between">
      <img
        src="https://cdn.bubbleplan.cn/static/img/contactus_back.png"
        alt=""
        class="contactus-title-img"
        @click="back()"
      />
      <img
        src="https://cdn.bubbleplan.cn/static/img/contactus_logo.png"
        alt=""
        class="contactus-title-logo"
      />
      <div class="contactus-title-div"></div>
    </div>
    <div class="contactus-ti">
      <div class="contactus-ti-text">邮箱：loki@bubbleplan.cn</div>
    </div>
    <div class="contactus-list flex flex-center">
      <div
        v-for="(item, index) in list"
        :key="index"
        class="contactus-list-li"
        @click="skip(item)"
      >
        <img :src="item.img" alt="" class="contactus-list-img" />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      list: [
        {
          img: "https://cdn.bubbleplan.cn/static/img/dy.png",
          url: "https://www.douyin.com/user/MS4wLjABAAAAHzUqAlTLfiBKH7RYr6lfWFxMXP6NoqQcMo7gDrQsaTQ",
        },
        {
          img: "https://cdn.bubbleplan.cn/static/img/wb.png",
          url: "https://weibo.com/u/7931073970",
        },
        {
          img: "https://cdn.bubbleplan.cn/static/img/xhs.png",
          url: "https://www.xiaohongshu.com/user/profile/668935d7000000000f03483d",
        },
        {
          img: "https://cdn.bubbleplan.cn/static/img/dd.png",
          url: "https://discord.gg/kWXY5Xr2bT",
        },
        {
          img: "https://cdn.bubbleplan.cn/static/img/tt.png",
          url: "https://x.com/Bubble_Plan",
        },
      ],
    };
  },
  methods: {
    back() {
      history.back();
    },
    skip(item) {
      window.open(item.url);
    },
  },
};
</script>

<style lang="scss" scoped>
.contactus {
  width: 100%;
  height: 100vh;
  margin-top: -94px;
  background: url("https://cdn.bubbleplan.cn/static/img/contactus.png")
    no-repeat;
  background-size: 100% 100%;
  padding: 230px 84px;
  &-title {
    &-img {
      width: 100px;
      height: 60px;
    }
    &-img:hover {
      cursor: pointer;
    }
    &-logo {
      width: 125.17px;
      height: 56.06px;
    }
    &-div {
      width: 100px;
    }
  }
  &-ti {
    display: table;
    margin: 26.94px auto;
    &-text {
      width: 447px;
      height: 66px;
      background: url("https://cdn.bubbleplan.cn/static/img/contactus_title.png")
        no-repeat;
      background-size: 100% 100%;
      color: #161421;
      text-align: center;
      font-size: 23px;
      line-height: 66px;
    }
  }
  &-list {
    margin-top: 22px;
    &-li {
      margin-right: 35px;
    }
    &-img {
      width: 289.28px;
      height: 258.87px;
    }
    &-li:nth-child(2) {
      margin-top: 64.83px;
    }
    &-li:nth-child(3) {
      margin-top: 10.95px;
    }
    &-li:nth-child(4) {
      margin-top: 54.41px;
    }
    &-li:hover {
      cursor: pointer;
    }
  }
}
</style>